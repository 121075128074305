<template>
  <div class="notice__page">
    <div class="item_title">
      <div class="item_titleline"></div>
      消息通知
    </div>
    <div class="content">
      <div class="notice_tab">
        <a-row>
          <a-col :span="2">
            <div @click="getItem('0')" :class="[status == 0 ? 'notice_tab_active' : '']">系统消息</div>
          </a-col>
          <a-col :span="2">
            <div @click="getItem('2')" :class="[status == 2 ? 'notice_tab_active' : '']">未读消息</div>
          </a-col>
          <a-col :span="2">
            <div @click="getItem('1')" :class="[status == 1 ? 'notice_tab_active' : '']">已读消息</div>
          </a-col>

          <a-col :span="18" style="text-align: right">
            <a-button type="primary" v-if="this.status === '2'" :disabled="disabled" @click="updateByUser" v-perms="'read-all'">
              全部已读
            </a-button>
            <a-button
              type="primary"
              v-if="['1', '2'].includes(this.status)"
              :disabled="this.checkList.length === 0"
              @click="Delete"
              v-perms="'delete'"
            >
              删除
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="notice_content">
        <v-card-list
          ref="cardList"
          :getDataApi="getDataApi"
          :renderItem="renderItem"
          :colConfig="{
            span: 24,
          }"
        >
          <a-empty class="empty" slot="empty" />
        </v-card-list>
      </div>
    </div>
  </div>
</template>

<script>
import { employmentMessagelist, getSystemMsg, UpdataemploymentMessage, updateByUserId, batch } from '@/api/notice'
import { dateFormat } from '@/common/common'
import noticeItem from './notice-item.vue'

export default {
  name: 'NoticePage',
  components: {
    'notice-item': noticeItem
  },
  data () {
    return {
      disabled: true,
      status: '0',
      rowItemId: null,
      confirmLoading: false,
      visible: false,
      obj: {},
      checkList: []
    }
  },
  methods: {
    Delete () {
      const that = this
      this.$confirm({
        title: '删除信息',
        content: (h) => <div style="color:red;">确定删除当前选择的{this.checkList.length}条数据吗？</div>,
        onOk () {
          batch(that.checkList).then((res) => {
            that.$message.success('删除成功')
            that.checkList = []
            that.$refs.cardList.refresh()
            employmentMessagelist({
              status: 2
            }).then((res) => {
              this.$eventBus.$emit('count', res.totalCount)
            })
          })
        },
        class: 'test'
      })
    },
    updateByUser () {
      updateByUserId().then((res) => {
        employmentMessagelist({
          status: this.status
        }).then((res) => {
          this.$eventBus.$emit('count', res.totalCount)
        })
        this.$refs.cardList.refresh()
      })
    },
    getItem (val) {
      if (this.status !== val) {
        this.status = val
        this.checkList = []
        this.$refs.cardList.pageInit()
      }
    },
    handleItemClick (row, index) {
      if (this.rowItemId === row.id) {
        this.rowItemId = null
      } else {
        this.rowItemId = row.id
      }
      if (!row.isRead && this.status === '2') {
        this.$getPerms('read') && UpdataemploymentMessage({
          messageId: row.id
        }).then(() => {
          this.$set(this.$refs.cardList.cardList, index, {
            ...row,
            isRead: true
          })
          employmentMessagelist({
            status: this.status
          }).then((res) => {
            this.$eventBus.$emit('count', res.totalCount)
          })
        })
      }
    },
    getDataApi (params) {
      const fun = this.status === '0' ? getSystemMsg : employmentMessagelist
      const model =
        this.status === '0'
          ? {
              clientId: 'enterprise'
            }
          : {
              status: this.status
            }
      return fun({
        ...params,
        ...model
      }).then((res) => {
        if (this.status === '2') {
          if (res.totalCount === 0) {
            this.disabled = true
          } else {
            this.disabled = false
          }
        }
        return res
      })
    },
    change (e, id) {
      const index = this.checkList.indexOf(id)
      if (index !== -1) {
        this.checkList.splice(index, 1)
      } else {
        this.checkList.push(id)
      }
    },
    renderItem ({ item, index, data }) {
      return (
        <div>
          {this.status !== '0' && (
            <div style="float: left;margin-top:20px;width:2%">
              <a-checkbox
                {...{
                  on: {
                    change: (e) => {
                      this.change(e, item.id)
                    }
                  }
                }}
              ></a-checkbox>
            </div>
          )}
          <div
            style="float: left;cursor: pointer;width:98%"
            class={{
              'card--item': this.status === '2',
              'card--item--read': item.isRead
            }}
            onClick={this.handleItemClick.bind(null, item, index)}
          >
            <notice-item
              {...{
                props: {
                  rowItem: item,
                  dataId: this.rowItemId
                }
              }}
            ></notice-item>
          </div>
        </div>
      )
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
.notice__page {
  height: 100%;
  width: 100%;
  .item_title {
    height: 28px;
  }
  .content {
    height: calc(100% - 40px);
    .notice_tab {
      height: 46px;
    }
    .notice_content {
      height: calc(100% - 46px);
    }
  }
}
</style>
<style scoped lang="less">
@import url('./index');
</style>
