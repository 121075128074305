<template>
  <div
    :class="{
      'notice-item': true,
      'is-collapse': isCollapse,
      'active': dataId === rowItem.id && isCollapse
    }"
  >
    <div class="notice-item-header">
      <div class="notice-item-title">{{ rowItem.title || rowItem.name }}</div>
      <div class="notice-item-date">{{ formatTime(rowItem.createTime,'YYYY-MM-DD HH:mm') }}</div>
    </div>
    <div class="notice-item-body">
      <div class="notice-item-content">{{ rowItem.content }}</div>
      <div class="notice-item-collapse" v-if="isCollapse">
        <a-icon :type="`caret-${ dataId !== rowItem.id ? 'right' : 'down'}`" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticeItem',
  props: {
    rowItem: {
      type: Object,
      default: () => ({})
    },
    // eslint-disable-next-line vue/require-default-prop
    dataId: {
      type: String
    }
  },
  data () {
    return {
      isCollapse: false
    }
  },
  mounted () {
    this.handCheckIsCollapse()
  },
  methods: {
    handCheckIsCollapse () {
      const range = document.createRange()
      const tEle = this.$el.querySelector('.notice-item-content')
      range.setStart(tEle, 0)
      range.setEnd(tEle, tEle.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      if (rangeWidth > tEle.offsetWidth || tEle.scrollWidth > tEle.offsetWidth) {
        this.isCollapse = true
      }
    }
  }
}
</script>

<style lang="less">
.notice-item {
  min-height: 70px;
  padding: 0px 25px;
  width: 100%;
  position: relative;
  margin-top: 10px;
  &.is-collapse{
    cursor: pointer;
  }
  &::after {
    content: '';
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #f0f0f0;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  &-title {
    font-size: 16px;
    font-weight: 550;
    color: #333333;
  }
  &-date {
    font-size: 14px;
    font-weight: 550;
    color: #333333;
  }
  &-body {
    display: flex;
  }
  &-content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-collapse {
    width: 40px;
    text-align: right;
  }
  &.active {
    .notice-item-content {
      white-space: normal;
      overflow: inherit;
      text-overflow: clip;
      word-break: break-all;
    }
  }
}
</style>
